import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          id
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allSanityProduct {
        edges {
          node {
            id
            name
            slug {
              current
            }
            poster {
              asset {
                fluid(maxWidth: 200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const products = data.allSanityProduct.edges.map((each) => each.node)

  return (
    <Layout>
      <SEO title="Ken Global Flavours" />
      <Img
        fluid={data.banner.childImageSharp.fluid}
        alt="ken global flavours banner"
      />
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-shrink-0 flex-wrap -mx-2">
          {products.map((product) => (
            <div className="w-1/2 md:w-1/3 px-4 my-6" key={product.id}>
              <article className="rounded shadow-lg bg-white">
                <Link className="" to={`/${product.slug.current}`}>
                  <Img
                    fluid={product.poster.asset.fluid}
                    className=""
                    alt={product.name}
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">{product.name}</div>
                  </div>
                </Link>
              </article>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
